@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.signupTitle {
  margin-top: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.artistNameRoot {
  margin-bottom: 24px;
}

.contactInfo {
  font-size: 16px;
  font-weight: var(--fontWeightBold);
}

.name {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.nameFieldsWrapper {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (--viewportSmall) {
    flex-wrap: nowrap;
  }
}

.firstNameRoot {
  flex: 1 1 45%;
}

.lastNameRoot {
  flex: 1 1 55%;
}

.field {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.phoneNumberRoot {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.addressRoot {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  margin-top: 40px;
}
